<template>
    <auth-layout>
        <div class="ce-login-card">
            <h3 v-if="route === '/recover-email'" class="ce-login-card-title">Recover Email</h3>
            <h3 v-else class="ce-login-card-title">Reset Password</h3>
            <div v-if="route == '/recover-email'"  class="ce-login-card-item-wrap">
                <input type="text" name="resetType" value="username" hidden="">
                <div class="ce-login-card-item-wrap-content">
                    <p>Please provide a phone number so that we can send your email to you !!! </p>
                    <div class="form-group reset_type ce-login-card-item-wrap-input ce-byphone" >
                        <input type="text" v-model="phone" class="form-control" name="phone" value=""
                               placeholder="Phone Number...">
                    </div>
                </div>

                <div class="ce-login-btn-wrap  ce-reset-username-wrap">
                    <button type="submit" v-on:click="sendMessage" class="btn ce-btn-verification-submit">
                        Send
                    </button>
                    <a href="/" class="btn ce-btn-cancel-submit">
                        Cancel
                    </a>
                </div>
            </div>
            <div v-else class="ce-login-card-item-wrap">
                <input type="text" name="resetType" value="username" hidden="">
                <div class="form-group" id="radio-email">
                    <label class="radio-inline">
                        <input name="plan" value="email" type="radio" checked="" v-model="selected_value">By Email
                    </label>
                </div>
                <div class="form-group" id="radio-phone">
                    <label class="radio-inline">
                        <input value="phone" name="plan" type="radio" v-model="selected_value">By Phone
                    </label>
                </div>


                <div class="ce-login-card-item-wrap-content">
                    <div v-if="selected_value == 'email'" class="form-group ce-login-card-item-wrap-input ce-byemail"
                         id="reset_type_email">
                        <input id="email" type="email" v-model="email" class="form-control" name="email" value=""
                               :disabled="loggedIn"
                               required=""
                               placeholder="Email Address...">
                    </div>

                    <div v-else class="form-group reset_type ce-login-card-item-wrap-input ce-byphone"
                         id="reset_type_phone">
                        <input id="phone" type="text" v-model="phone" class="form-control" name="phone" value=""
                               placeholder="Phone Number...">
                    </div>
                </div>

                <div class="ce-login-btn-wrap  ce-reset-username-wrap">
                    <button type="submit" v-on:click="submit" class="btn ce-btn-verification-submit">
                        Send Verification Code
                    </button>
                    <a href="/" class="btn ce-btn-cancel-submit">
                        Cancel
                    </a>
                </div>
            </div>

        </div>
    </auth-layout>
</template>
<script>
    import AuthLayout from '../layouts/AuthLayout';
    import Api from '../includes/Api';
    import {mapState} from 'vuex';

    export default {
        data() {
            return {
                loggedIn: false,
                selected_value: 'email',
                route: this.$router.currentRoute.path,
                phone: '',
                email: ''
            }
        },
        components: {
            'auth-layout': AuthLayout
        },
        computed: mapState('User', ['user']),
        methods: {
            submit() {
                let root = this;
                let data = '';
                if (root.selected_value === 'email') {
                    data = root.email;
                } else {
                    data = root.phone;
                }
                Api.post('/reset-password', {data: data, option: root.selected_value}).then((response) => {

                  if( response.data.status === 'success' ) {
                    Api.alert('success',response.data.message);
                    root.$router.push('/');
                  }
                });
            },
            sendMessage() {
                let root = this;
                Api.post('/recover-email',{phone: root.phone}).then((response) => {
                  if( response.data.status === 'success' ) {
                    Api.alert('success',response.data.message);
                    root.$router.push('/');
                  }
                })
            }
        },
        created() {
            let root = this;
            if (root.$router.currentRoute.path === '/user/reset-password') {
                root.loggedIn = true;
                root.phone = root.user.phone;
                root.email = root.user.email;
            }
            // if (root.$router.currentRoute.path === '/recover-email') {
            //
            // }
        }
    }
</script>
